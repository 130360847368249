import api from './index'
import { axios } from '@/utils/request'

// 获取可替换变量
export function SmsReplaceVars() {
    return axios({
        url: api.SmsReplaceVars,
        method: 'get',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取短信模板
export function smsTemplate(parameter) {
    return axios({
        url: api.SmsTemplate,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 新增短信模板
export function smsAddTemplate(parameter) {
    return axios({
        url: api.SmsAddTemplate,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 修改短信模板
export function smsEditTemplate(parameter) {
    return axios({
        url: api.SmsEditTemplate,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 删除短信模板
export function smsDelTemplate(parameter) {
    return axios({
        url: api.SmsDelTemplate,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取发送对应模板设置
export function smsSelectSet(parameter) {
    return axios({
        url: api.SmsSelectSet,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 保存短信发送设置
export function saveSmsSelectSet(parameter) {
    return axios({
        url: api.SaveSmsSelectSet,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取短信日志
export function smsLog(parameter) {
    return axios({
        url: api.SmsLog,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}
